<template>
<div id="CourseWelcome" v-if="CourseData != null">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<v-container fluid >

<div class="titleDiv px-5">
  <p class="display-1">{{CourseData.title}}</p>
</div>

<div>
  <!-- above content (image, time, etc) -->
  <v-row>

    <v-col cols="12" sm="12" md="5" class="d-flex align-center justify-center">
         <v-img :src="CourseData.imageUrl" height="250px" aspect-ratio="1" alt="Course Image" 
               class="elevation-10 white animated fadeIn slow">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
    </v-col>

    <v-col cols="12" sm="12" md="7" class="d-flex align-center justify-center">
             <v-row>
                <v-col cols="12" sm="12" md="6">
                    <p class="title mb-1">Eligibility :</p>
                    <p class="subtitle-1 mb-1">{{CourseData.eligibility}}</p>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="CourseData.subjects.length > 1">
                    <div>
                        <p class="title mb-1">Subjects :</p>
                        <p class="subtitle-1 mb-1">{{SubjectsString}}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <p class="title mb-1">Time :</p>
                    <p class="subtitle-1 mb-1">{{CourseData.time}}</p>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <p class="title mb-1">Support : 24/7</p>
                </v-col>
            </v-row>
    </v-col>

  </v-row>

  <!-- description -->
  <div class="card-description">
     <p class="title">Description :</p>
     <p class="subtitle-1">{{CourseData.description}}</p>
  </div>

</div>

<!-- interest form -->
<div class="contact-form">
<v-card class="py-5 elevation-5 pa-1">
    <v-card-title><p class="display-1">I'm Interested</p></v-card-title>
    <v-card-text>
    <form @submit.prevent="Submit()">
        <v-row>   
            <v-col cols="12" sm="12" md="4">
                <v-text-field label="Name" :error="$v.Name.$error" :error-messages="NameErrors" 
                v-model.trim="Name" @input="$v.Name.$touch()" required ></v-text-field>       
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field type="email" label="Email" :error="$v.Email.$error" :error-messages="EmailErrors"
                v-model.trim="Email" @input="$v.Email.$touch()" required ></v-text-field>       
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field type="number" label="Phone" :error="$v.Phone.$error" :error-messages="PhoneErrors" 
                v-model="Phone" @input="$v.Phone.$touch()" required ></v-text-field>       
            </v-col>
        </v-row>
        <v-textarea label="Description (Optional)" v-model="Description" rows="6" ></v-textarea>
        <div class="d-flex align-center justify-center py-5">
        <v-btn type="submit" fab bottom absolute dark class="pink elevation-5">
            <v-icon :class="{'mdi-spin': disbtn}">mdi-telegram</v-icon>
        </v-btn>
        </div>
    </form>
    </v-card-text>
</v-card>
</div>

</v-container>
</div>
</template>

<script>
// imports
import { required, email } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/functions";

export default {

    name: 'CourseWelcome',

    data(){return{
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,
        // extra
        Name: '', Email: '', Phone: '',Description: '',
    }},

    computed:{

        CourseSlug(){         
            let data = this.$route.params.courseslug != null ? this.$route.params.courseslug : null;
            return data;
        },

        CourseData(){
            if(this.CourseSlug != null) {
                let data =  this.$store.getters.GetCourseWithSlug(this.CourseSlug);
                return data != '' ? data[0] : null;
            }else{ return this.$router.push("/"); }             
        },

        SubjectsString(){
            if(!this.CourseData){return false;}
            if(this.CourseData.subjects.length > 1){
                let index = this.CourseData.subjects.indexOf('All');
                if(index != -1){ this.CourseData.subjects.splice(index, 1);}
                return this.CourseData.subjects.toString()
            }
        },

        // basically in computed, i added validations  
        // sorry, i cant find a way to make this even smaller : -D
        NameErrors () { return this.ErrorPusher(this.$v.Name.$dirty, this.$v.Name.required, 'Name is Required'); },
        EmailErrors () { return this.ErrorPusher(this.$v.Email.$dirty, this.$v.Email.email, 'A Valid Email is Required'); },
        PhoneErrors () { return this.ErrorPusher(this.$v.Phone.$dirty, this.$v.Phone.required, 'Phone is Required'); },

    },

    methods: {

        Submit(){

            if(!this.BlankValidation()){return false;}    
            let CustomDescription = '';
            let ename = this.Name;
            this.disbtn = true; 
            if(this.Description == '' && this.CourseData != null){
                CustomDescription = `Hi, I'm ${ename} I love to know more about The ${this.CourseData.title} course`;
            }else{
                CustomDescription = this.Description;
            }

            let contentMsg = `
                            <h1>New Request For The ${this.CourseData.title} Course</h1>
                            <p>Name: ${ename}</p>
                            <p>Email: ${this.Email}</p>
                            <p>Phone: ${this.Phone}</p>
                            <p>Description : </p>
                            <p>${CustomDescription}</p>
                            `;
            
            let SendMail =  firebase.functions().httpsCallable('sendMail');
            SendMail({subject: 'Course Enquiry', content: contentMsg}).then((per) => {
                this.notificationService("Thank You, We'll Contact You Soon!", "info");
                this.disbtn = false;this.ResetFields();
            }).catch((err) => {console.log(err)});
            
        },

        // reset fields
        ResetFields(){     
            this.Name = '';this.Email = '';this.Phone = null,this.Description = '';
            this.$v.$reset();
        },

        // check if all fileds are empty   
        BlankValidation(){
            if(this.Name == '' || this.Email == '' || this.Phone == null)
            {this.notificationService("Please Fill All Fields!", "red darken-1"); return false;}else{return true;}
        },

        // error pusher for vuelidate   
        ErrorPusher(basic, extra, msg){
            const errors = []
            if (!basic) return errors
            !extra && errors.push(msg);
            return errors;
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },


    },

    // validations
    validations: {
        Name: { required }, 
        Email: { required, email },
        Phone: { required },
    },
    
}
</script>

<style scoped>
#CourseWelcome{
  padding: 3vw;
  padding-top: 2rem !important;
  color: #ddd;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 2rem;
  color: #eee;
}
.contact-form{
    padding-top: 3rem !important;
}
@media (min-width: 900px) {

    .card-description{
        padding-top: 2rem !important;
    }

    .contact-form{
        padding-left: 3vw !important;
        padding-right: 3vw !important;
    }

}
</style>